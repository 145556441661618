import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { FidgetSpinner } from "react-loader-spinner";
import './App.css';
import ReactGA4 from 'react-ga4';

// Lazily import your components
const Home = lazy(() => import('./components/Home'));
const Contact = lazy(() => import('./components/Contact'));
const Products = lazy(() => import('./components/Products'));
const About = lazy(() => import('./components/About'));
const ProductDetail = lazy(() => import('./components/ProductDetail'));
const CategoryProducts = lazy(() => import('./components/CategoryProducts'));

function TrackPageViews() {
  let location = useLocation();

  useEffect(() => {
    // Correct approach to send page views, ensure this matches the react-ga4 documentation
    ReactGA4.send({
      hitType: 'pageview',
      page_path: location.pathname + location.search,
    });
  }, [location]);

  return null; // This component doesn't render anything
}

function App() {
  
  useEffect(() => {
    // Deferred GA4 initialization
    const TRACKING_ID = "G-XVPGPJ6BZV";
    ReactGA4.initialize(TRACKING_ID);
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <TrackPageViews />
      <Navbar />
      <Suspense fallback={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <FidgetSpinner backgroundColor="#C8AF74" ballColors="#C8AF74" height={80} width={80} />
                          </div>}>
      <Routes>
        <Route path="/" element={<Home />} /> {/* Home includes HeroSlider */}
        <Route path="/products" element={<Products />} />
        <Route path="/about-company" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/product/:id" element={<ProductDetail />} />
        <Route path="/products/:categoryId" element={<CategoryProducts />} />
        {/* Additional Routes if needed */}
      </Routes>
      </Suspense>
      <Footer />
    </Router>
  );
}

export default App;
