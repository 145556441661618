// Footer.js
import React from "react";
import { Container, Box, Grid, Typography, Link } from "@mui/material";
import { FaEnvelope, FaFacebook, FaLinkedin, FaPhone, FaWhatsapp } from "react-icons/fa";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const logo = `${process.env.PUBLIC_URL}/images/pew-without-logo.avif`;
  return (
    <Box sx={{ pt: 8 }}>
      <Box
        component="footer"
        sx={{
          bgcolor: "#212529",
          color: "#f8f9fa",
          py: 3,
          borderTop: "1px solid",
          borderColor: "grey.800",
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Box
                component="img"
                sx={{
                  height: "auto",
                  width: "100%",
                  maxWidth: { xs: 120, md: 150 },
                  mb: 2,
                }}
                src={logo}
                alt="Brand Logo"
              />
              <Typography variant="subtitle1" color="#f8f9fa" gutterBottom>
                We are renowned manufacturers of machine parts for various
                industrial machineries, offering components in various
                specifications.
              </Typography>
              <Box
                sx={{
                  mt: 2,
                  fontSize: { xs: "1.2rem", md: "1.5rem" },
                  "& svg": {
                    mr: 1.5,
                  },
                }}
              >
                <a
                  href="https://www.facebook.com/paulenggworks"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook className="social-icon" />
                </a>
                <a
                  href="https://www.linkedin.com/company/paul-engg-works/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin className="social-icon" />
                </a>
                <a
                  href="https://wa.me/918527001952"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaWhatsapp className="social-icon" />
                </a>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="h6"
                color="#c8af74"
                gutterBottom
                sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
              >
                Quick Links
              </Typography>
              <Typography>
                <Link
                  href="/"
                  color="inherit"
                  sx={{
                    display: "block",
                    fontSize: { xs: "0.875rem", sm: "1rem" },
                  }}
                >
                  Home
                </Link>
              </Typography>
              <Typography>
                <Link
                  href="/products"
                  color="inherit"
                  sx={{
                    display: "block",
                    fontSize: { xs: "0.875rem", sm: "1rem" },
                  }}
                >
                  Products
                </Link>
              </Typography>
              <Typography>
                <Link
                  href="/about-company"
                  color="inherit"
                  sx={{
                    display: "block",
                    fontSize: { xs: "0.875rem", sm: "1rem" },
                  }}
                >
                  About
                </Link>
              </Typography>
              <Typography>
                <Link
                  href="/contact"
                  color="inherit"
                  sx={{
                    display: "block",
                    fontSize: { xs: "0.875rem", sm: "1rem" },
                  }}
                >
                  Contact Us
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="h6"
                color="#c8af74"
                gutterBottom
                sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
              >
                Contact Info
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <FaPhone />
                <Typography
                  variant="subtitle1"
                  sx={{ ml: 1, fontSize: { xs: "0.875rem", sm: "1rem" } }}
                >
                  +91-8527001952
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <FaEnvelope />
                <Typography
                  variant="subtitle2"
                  sx={{ ml: 1, fontSize: { xs: "0.875rem", sm: "1rem" } }}
                >
                  info@paulenggworks.com
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Typography
            variant="body2"
            color="grey.300"
            align="center"
            sx={{ mt: 3 }}
          >
            Copyright &copy; Paul Engg Works 2023-{currentYear}. All rights
            reserved.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
