import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import {
  useMediaQuery,
  useTheme,
  AppBar,
  Toolbar,
  Grid,
  IconButton,
  Typography,
  Box,
  Container,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "./Navbar.css";
import { db } from "../firebaseConfig";
import { collection, getDocs, query, orderBy } from "firebase/firestore";

const fetchCategories = async () => {
  const categoriesCol = collection(db, "categories"); // Replace 'categories' with your actual collection name
  const q = query(categoriesCol, orderBy("name", "asc")); // Ordering by Category Name
  const querySnapshot = await getDocs(q);

  const categoriesArray = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return categoriesArray;
};

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [categories, setCategories] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const productsAccordionRef = useRef(null);

  useEffect(() => {
    const loadCategories = async () => {
      const fetchedCategories = await fetchCategories();
      setCategories(fetchedCategories);
    };

    loadCategories();
  }, []);

  const handleClickOutside = (event) => {
    if (
      productsAccordionRef.current &&
      !productsAccordionRef.current.contains(event.target)
    ) {
      setIsProductsOpen(false);
    }
  };

  useEffect(() => {
    // Bind the event listener
    window.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const logo = `${process.env.PUBLIC_URL}/images/pew-logo-new.avif`;

  return (
    <>
      <AppBar
        position="static"
        sx={{ backgroundColor: "#353839", padding: "4px 0" }}
      >
        <Container maxWidth="x1">
          <Toolbar variant="dense" disableGutters={!isMobile}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  justifyContent: isMobile ? "flex-start" : "flex-start",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="email"
                    size="small"
                    sx={{ padding: "4px" }}
                  >
                    <EmailIcon fontSize="inherit" />
                  </IconButton>
                  <Typography
                    variant="body2"
                    noWrap
                    sx={{
                      lineHeight: "1.2",
                      fontSize: "0.8125rem",
                      padding: "4px",
                    }}
                  >
                    info@paulenggworks.com
                  </Typography>
                </Box>
                {isMobile && <Box flexGrow={1} />}{" "}
                {/* This box pushes the email to the right on mobile */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: isMobile ? "auto" : "16px",
                  }}
                >
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="phone"
                    size="small"
                    sx={{ padding: "4px" }}
                  >
                    <PhoneIcon fontSize="inherit" />
                  </IconButton>
                  <Typography
                    variant="body2"
                    noWrap
                    sx={{
                      lineHeight: "1.2",
                      fontSize: "0.8125rem",
                      padding: "4px",
                    }}
                  >
                    +91-8527001952
                  </Typography>
                </Box>
              </Grid>
              {!isMobile && (
                <Grid
                  item
                  sm={6}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <IconButton
                    color="inherit"
                    aria-label="facebook"
                    href="https://www.facebook.com/paulenggworks"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ padding: "4px" }}
                  >
                    <FacebookIcon />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    aria-label="linkedin"
                    href="https://www.linkedin.com/company/paul-engg-works/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ padding: "4px" }}
                  >
                    <LinkedInIcon />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    aria-label="whatsapp"
                    href="https://wa.me/918527001952"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ padding: "4px" }}
                  >
                    <WhatsAppIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      <nav className="navbar sticky-top navbar-expand-lg navbar-dark bg-dark ps-3 pe-3">
        <a className="navbar-brand" href="/">
          <img
            className="logo-image d-inline-block align-text-top"
            src={logo}
            alt=""
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggle}
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
          id="navbarNav"
        >
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <NavLink className="nav-link" to="/" onClick={toggle}>
                Home
              </NavLink>
            </li>
            {isMobile ? (
              <div
                ref={productsAccordionRef}
                className="accordion accordion-flush"
                id="accordionProducts"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingProducts">
                    <button
                      className={`accordion-button ${
                        isProductsOpen ? "" : "collapsed"
                      }`}
                      type="button"
                      onClick={() => setIsProductsOpen(!isProductsOpen)}
                    >
                      Products
                    </button>
                  </h2>
                  <div
                    id="flush-collapseProducts"
                    className={`accordion-collapse collapse ${
                      isProductsOpen ? "show" : ""
                    }`}
                  >
                    <div className="accordion-body">
                      {categories.map((category, index) => (
                        <NavLink
                          key={index}
                          className="dropdown-item"
                          to={`/products/${category.id}`}
                          onClick={toggle}
                        >
                          {category.name}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <li className="nav-item dropdown">
                <NavLink
                  className="nav-link dropdown-toggle"
                  to="/products"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Products
                </NavLink>
                <ul className="dropdown-menu">
                  {categories.map((category, index) => (
                    <li key={index}>
                      <NavLink
                        className="dropdown-item"
                        to={`/products/${category.id}`}
                      >
                        {category.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
            )}
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/about-company"
                onClick={toggle}
              >
                About
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact" onClick={toggle}>
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
