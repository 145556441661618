// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDndnQvkQhxJwh8HWZ6FsC3Qn3BerV7NRE",
  authDomain: "pew-01-a9033.firebaseapp.com",
  projectId: "pew-01-a9033",
  storageBucket: "pew-01-a9033.appspot.com",
  messagingSenderId: "467533170764",
  appId: "1:467533170764:web:3c59be8a99314e105fcce9",
  measurementId: "G-XVPGPJ6BZV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);